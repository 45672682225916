import ServicesContext from "./ServiceContext";
import AdminState from "./ContextStore/AdminState";
import SeoState from "./ContextStore/SeoState";
import ProjectState from "./ContextStore/ProjectState";
import ContactState from "./ContextStore/ContactState";
import AppointmentState from "./ContextStore/AppointmentState";
import CommentatorState from "./ContextStore/CommentatorState";
import VisitorState from "./ContextStore/VisitorState";
import CareerState from "./ContextStore/CareerState";
import NotificationState from "./ContextStore/NotificationState";
import PostJobState from "./ContextStore/PostJobState";
import DepartmentState from "./ContextStore/DepartmentState";
import TeamState from "./ContextStore/TeamState";

const ServiceProvider = ({ children }) => {
  return (
    <ServicesContext.Provider
      value={{
        AdminState: AdminState,
        SeoState: SeoState,
        ProjectState: ProjectState,
        ContactState: ContactState,
        AppointmentState: AppointmentState,
        CommentatorState: CommentatorState,
        VisitorState: VisitorState,
        CareerState: CareerState,
        NotificationState: NotificationState,
        PostJobState: PostJobState,
        DepartmentState: DepartmentState,
        TeamState: TeamState,
      }}
    >
      {children}
    </ServicesContext.Provider>
  );
};

export default ServiceProvider;
